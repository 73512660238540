import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { ChmerIntroStyles } from "./ChmerIntroStyles"
import { chmerAwardList } from "../../constants/chmerIntroData"
import PageLinks from "../PageLinks/PageLinks"
import { Fade } from "react-awesome-reveal"

const ChmerIntro = () => {
  return (
    <>
      <ChmerIntroStyles>
        <div className="container">
          <Fade duration="2000">
            <section className="title__section">
              <Fade direction="up" cascade>
                <div className="title">
                  <p className="header__excerpt">INTRODUCING CHMER EDM</p>
                  <h1>CHMER 대만본사를 소개합니다.</h1>
                </div>
              </Fade>

              <div className="img__container">
                <StaticImage
                  className="title__logo image"
                  src="../../images/chmer/chmerlogopic-min.png"
                  layout="fullWidth"
                  placeholder="blurred"
                />
                <StaticImage
                  className="title__image image"
                  src="../../images/chmer/chmerbuildingpic-min.png"
                  alt="edm title pic"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              </div>
            </section>
          </Fade>

          <section className="desc__section">
            <div className="desc1 desc">
              <Fade delay="10">
                <div className="desc1__text desc__text">
                  <p className="header__excerpt">PROVIDING CUTTING EDGE EDM</p>
                  <h1 className="desc1__header desc__header">
                    <span>CHMER EDM </span>FOUNDATION
                  </h1>

                  <div className="content1 content">
                    <div className="content__text">
                      <Fade
                        direction="right"
                        duration="400"
                        className="z__index__fix"
                      >
                        <div className="content__h2">
                          <h2>INTEGRITY, GROWTH, CUSTOMER SATISFACTION</h2>
                        </div>
                      </Fade>
                      <Fade direction="right">
                        <div className="content__p">
                          <p>
                            CHMER EDM은 1975년 성실, 성장, 고객만족, 직원의
                            안전을 창업이념으로 하여 설립되었습니다. 그 후로
                            대만 및 전세계 시장의 방전기 산업의 기술적, 질적
                            향상을 위하여 노력을 다 해왔습니다.
                          </p>
                        </div>
                      </Fade>
                    </div>

                    <StaticImage
                      className="desc1__image image desc__image"
                      src="../../images/chmer/chmerpic16-min.png"
                      alt="edm desc1 pic"
                      layout="fullWidth"
                      placeholder="blurred"
                    />
                  </div>
                </div>
              </Fade>
            </div>
            <div className="desc2 desc">
              <Fade delay="10">
                <div className="desc2__text desc__text">
                  <p className="header__excerpt">WIDE RANGE OF 42+ AWARDS</p>
                  <h1 className="desc2__header desc__header">
                    <span>HISTORY </span>AWARDS
                  </h1>

                  <div className="content2 content">
                    <Fade
                      direction="left"
                      duration="400"
                      className="z__index__fix"
                    >
                      <div className="content__text">
                        <div className="content__h2">
                          <h2>WORLD'S 5TH LARGEST EDM MANUFACTURER</h2>
                        </div>
                        <Fade cascade duration="300" direction="up">
                          <ul className="content__ul">
                            {chmerAwardList.map((item, i) => (
                              <li key={i}>
                                <p>
                                  <span>- </span>
                                  {item.text}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </Fade>
                      </div>
                    </Fade>

                    <StaticImage
                      className="desc2__image image desc__image"
                      src="../../images/chmer/chmeraward.png"
                      alt="edm desc2 pic"
                      layout="fullWidth"
                      placeholder="blurred"
                    />
                  </div>
                </div>
              </Fade>
            </div>
            <div className="desc3 desc">
              <Fade delay="10">
                <div className="desc3__text desc__text">
                  <h1 className="desc3__header desc__header">
                    <span>CHMER'S TODAY </span>
                    <br />
                    WHY CHMER?
                  </h1>

                  <div className="content3 content">
                    <div className="content__text">
                      <Fade
                        direction="right"
                        duration="400"
                        className="z__index__fix"
                      >
                        <div className="content__h2">
                          <h2>HIGHEST QUALITY DESIGN AND ASSEMBLY</h2>
                        </div>
                      </Fade>
                      <Fade direction="right">
                        <div className="content__p">
                          <p>
                            최상의 제품을 목표로 강력한 기술력과 서비스로 무장한
                            해외 영업소 및 대리점이 전세계 55개국에 75개에
                            달하며, 연간 수출액이 동기간에만 대만 수출액의 20%를
                            넘어 섰습니다.
                          </p>
                        </div>
                      </Fade>
                    </div>
                    <StaticImage
                      className="desc3__image image desc__image"
                      src="../../images/chmer/chmerpic12-min.png"
                      alt="edm desc3 pic"
                      layout="fullWidth"
                      placeholder="blurred"
                    />
                  </div>
                </div>
              </Fade>
            </div>
          </section>
        </div>
      </ChmerIntroStyles>
      <PageLinks />
    </>
  )
}

export default ChmerIntro
