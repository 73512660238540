import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import { StaticImage } from "gatsby-plugin-image"
import Features from "../components/Features/Features"
import ChmerIntro from "../components/ChmerIntro/ChmerIntro"

const edm = () => {
  return (
    <>
      <Seo title="EDM" />
      <Layout>
        <SimpleBanner title="방전가공">
          <StaticImage
            className="banner__image"
            src="../images/chmer/chmerpic2-min.png"
            alt="edm banner pic"
            layout="constrained"
            placeholder="blurred"
          />
        </SimpleBanner>
        <ChmerIntro />
        <Features />
      </Layout>
    </>
  )
}

export default edm
